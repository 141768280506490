.unreset p {
  @apply my-4;
}

.unreset ul,
menu {
  @apply pl-5 my-4 list-disc;
}

.unreset li>p {
  @apply my-0;
}

.unreset ol {
  @apply pl-5 my-4 list-decimal;
}

.unreset ol,
ul {
  list-style-type: circle;
}

.unreset ol,
ul {
  list-style-type: circle;
}