@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './unreset.css';

html,
body {
  @apply font-MAIN text-TEXT bg-BG;
}

body::-webkit-scrollbar {
  display: none;
}

blockquote {
  @apply pl-4 italic border-l-4 border-PRIMARY text-TEXT;
}